<template>
  <div class="position-technician">
    <b-row class="d-flex justify-content-between pb-3">
      <div class="username">
        {{ userName }}
      </div>
      <img
        v-if="contactedTechnician.favorite"
        class="fav-icon"
        @click="changeFavorite"
        :src="require('@/assets/icons/star-pink.svg')"
      >
      <img
        v-else
        class="fav-icon"
        @click="changeFavorite"
        :src="require('@/assets/icons/star.svg')"
      >
    </b-row>
    <b-row class="d-flex justify-content-between align-items-end">
      <b-col class="d-flex technician-info">
        <div>
          <img
            class="technician-avatar"
            :src="getAvatarUrl"
            alt="technician avatar"
          >
        </div>
        <div class="d-flex flex-column pl-4 info">
          <span class="pink roles font-weight-bold">{{ technicianRoles }}</span>
          <span>
            <img
              class="location-icon"
              :src="require('@/assets/icons/icon-location-input.svg')"
            >
            <span class="location pl-2">{{ technicianLocation }}</span>
          </span>
          <a class="profile font-weight-bold" @click="goToProfile">Ver perfil</a>
        </div>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        v-if="showButtons"
        class="d-flex buttons justify-content-between mt-3"
      >
        <b-button
          class="w-50 mr-1"
          variant="secondary"
          @click="goToProfileOpenMessage">
          <span>Contactar</span>
        </b-button>
        <b-button
          class="w-50 ml-1"
          variant="primary"
          @click="showConfirmationModal"
        >
          <span>Bookear</span>
        </b-button>
      </b-col>
      <b-col cols="12" sm="6" v-if="showCancelPosition" class="buttons text-center mt-3 text-sm-right">
        <b-button
          v-b-modal.cancel-technician
          class="cancel-button w-100"
          variant="secondary"
        >
          <span>Cancelar posición</span>
        </b-button>
        <ModalComponent
          id="cancel-technician"
          title="¿Cancelar posición?"
          ok-title="Aceptar"
          size="lg"
          @confirmed="onCancelPosition"
          @askConfirmationFunction="onCancelPosition"
          askConfirmationTitle="Cancelar posición"
          askConfirmationText="¿Seguro que quieres cancelar esta posición?"
          :askConfirmation="true"
          :validation="atLeastOneReasonSelected"
        >
          <BookingCancelPositionModal
            @addOption="addOption"
            @removeOption="removeOption"
            @changeCustomReason="changeCustomReason"
            :showWarning="!atLeastOneReasonSelected"
          />
        </ModalComponent>
      </b-col>
      <AskConfirmationModal
        :id="contactedTechnician.id"
        title="Seleccionar"
        :body="modalAcceptBody"
        @confirm="acceptTechnician"
      />
    </b-row>
  </div>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */

import ModalComponent from '@/components/Modal/ModalComponent.vue'
import BookingCancelPositionModal from '@/components/BookingPosition/BookingCancelPositionModal.vue'
import AskConfirmationModal from '../Modal/AskConfirmationModal'

export default {
  name: 'BookingPositionTechnician',
  components: {
    ModalComponent,
    BookingCancelPositionModal,
    AskConfirmationModal
  },
  props: {
    contactedTechnician: {
      type: Object,
      require: true
    },
    bookingId: {
      type: String,
      require: true
    },
    position: {
      type: Object,
      require: true
    },
    showButtons: {
      type: Boolean,
      require: true
    },
    showCancelPosition: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_IMAGE_API_URL,
      deleteReasonsSelected: [],
      customReason: ''
    }
  },
  computed: {
    user () {
      return this.contactedTechnician.user
    },
    technician () {
      return this.user.technician
    },
    userName () {
      if (this.user.firstName === '') {
        return this.technician.nickname
      }
      return this.user.firstName + ' ' + this.user.lastName
    },
    getAvatarUrl () {
      if (this.technician.avatar) {
        return this.baseUrl + this.technician.avatar.path
      }

      return '/img/default-user-avatar.svg'
    },
    technicianRoles () {
      if (!this.position) return
      const positionRolesIds = this.getPositionRolesIds()
      const roles = this.technician.roles.filter(rol => {
        return positionRolesIds.includes(rol.id)
      })

      return roles.map(rol => {
        return rol.name
      }).join(', ')
    },
    technicianLocation () {
      if (this.technician.location.administrative === null || this.technician.location.name === null) {
        return '------'
      }
      return this.technician.location.administrative + ', ' + this.technician.location.name
    },
    modalAcceptBody () {
      return `¿Quieres seleccionar a ${this.userName} para esta posición?`
    },
    atLeastOneReasonSelected () {
      return this.deleteReasonsSelected.length !== 0 || this.customReason !== ''
    }
  },
  methods: {
    getPositionRolesIds () {
      return this.position.getRoles.map(rol => {
        return rol.id
      })
    },
    goToProfile () {
      const routeData = this.$router.resolve({
        name: 'private-technician-profile',
        params: { id: this.user.id }
      })
      window.open(routeData.href, '_blank')
    },
    goToProfileOpenMessage () {
      const routeData = this.$router.resolve({
        name: 'private-technician-profile',
        params: { id: this.user.id },
        query: { openedMessageBox: true }
      })
      window.open(routeData.href, '_blank')
    },
    async updateContactedTechnicianFromBusiness (businessAccepted) {
      const data = {
        bookingId: this.bookingId,
        bookingPositionId: this.position.id,
        bookingContactedTechnicianId: this.contactedTechnician.id,
        businessAccepted: businessAccepted
      }

      const context = this
      this.$store.dispatch('updateContactedTechnicianFromBusiness', data)
        .then(function () {
          context.$emit('updateData')
        })
    },
    acceptTechnician () {
      this.updateContactedTechnicianFromBusiness(true).then(() => {
        this.$store.dispatch('showConfirmationModal')
      })
    },
    async cancelTechnician () {
      await this.updateContactedTechnicianFromBusiness(false)
    },
    async changeFavorite () {
      if (this.contactedTechnician.favorite === false) {
        await this.$store.dispatch(
          'addFavoriteTechnician',
          this.technician.id
        )
        this.contactedTechnician.favorite = true
      } else {
        await this.$store.dispatch(
          'deleteFavoriteTechnician',
          this.technician.id
        )
        this.contactedTechnician.favorite = false
      }
    },
    async onCancelPosition () {
      if (this.deleteReasonsSelected.length === 0 && this.customReason === '') {
        return false
      }

      const data = {
        bookingId: this.bookingId,
        bookingPositionId: this.position.id,
        customDeletedReason: this.customReason,
        deletedReasonsIds: this.deleteReasonsSelected.map(element => {
          return { id: element }
        })
      }

      await this.$store.dispatch('addDeleteReasons', data)

      this.cancelTechnician().then(() => {
        this.$store.dispatch('showConfirmationModal')
      })
    },
    addOption (optionId) {
      this.deleteReasonsSelected.push(optionId)
    },
    removeOption (optionId) {
      const index = this.deleteReasonsSelected.indexOf(optionId)
      if (index > -1) {
        this.deleteReasonsSelected.splice(index, 1)
      }
    },
    changeCustomReason (customReason) {
      this.customReason = customReason.target.value
    },
    showConfirmationModal () {
      this.$bvModal.show(this.contactedTechnician.id)
    }
  }
}
</script>
<style lang="less" scoped>
  @import '../../style/colors.less';
  @import '../../style/text';
  @import 'BookingPosition.less';

  .username {
    font-size: 24px;
  }

  .fav-icon {
    padding-right: 15px;
  }

  .roles, .profile {
    font-size: 16px;
  }

  .profile {
    cursor: pointer;
    color: @color_radiant_purple !important;
  }

  .location {
    font-size: 12px;
  }

  .technician-avatar {
    width: 80px;
    height: 80px;
    border: 4px solid @color_white;
    border-radius: 4px;
  }

  .info {
    line-height: 28px;
  }
  .buttons {
    padding-right: 14px;
  }

  .buttons > .btn {
    padding: 10px 0;
  }

  .position-technician {
    margin-bottom: 4rem;
    font-family: @font_primary;
  }
</style>
