<template>
  <div id="booking">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container class="booking-container">
      <b-row>
        <b-col md="4">
          <div class="tab-container d-md-none">
            <b-container class="d-flex justify-content-between">
              <Tab
                name="Abiertos"
                :selected="displayedCard == 'opened'"
                @onClick="goTo('bookings-opened')"
              />
              <Tab
                name="Cerrados"
                :selected="displayedCard == 'closed'"
                @onClick="goTo('bookings-closed')"
              />
              <Tab
                name="Vencidos"
                :selected="displayedCard == 'expired'"
                @onClick="goTo('bookings-expired')"
              />
            </b-container>
          </div>
          <b-card class="side-card d-none d-md-block">
            <b-row>
              <b-col>
                <div
                  class="item opened"
                  :class="{ 'selected': displayedCard == 'opened' }"
                  @click="goTo('bookings-opened')"
                >
                  <img
                    :src="require('@/assets/icons/booking-opened.svg')"
                    v-if="displayedCard !== 'opened'"
                  >
                  <img
                    :src="require('@/assets/icons/booking-opened-selected.svg')"
                    v-else
                  >
                  Abiertos
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  class="item closed"
                  :class="{ 'selected': displayedCard == 'closed' }"
                  @click="goTo('bookings-closed')"
                >
                  <img
                    :src="require('@/assets/icons/booking-closed.svg')"
                    v-if="displayedCard !== 'closed'"
                  >
                  <img
                    :src="require('@/assets/icons/booking-closed-selected.svg')"
                    v-else
                  >
                  Cerrados
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  class="item expired"
                  :class="{ 'selected': displayedCard == 'expired' }"
                  @click="goTo('bookings-expired')"
                >
                  <img
                    :src="require('@/assets/icons/booking-expired.svg')"
                    v-if="displayedCard !== 'expired'"
                  >
                  <img
                    :src="require('@/assets/icons/booking-expired-selected.svg')"
                    v-else
                  >
                  Vencidos
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col col>
          <slot></slot>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Tab from '@/components/Tab/Tab.vue'

export default {
  name: 'Booking',
  components: { Header, Tab },
  props: {
    displayedCard: {
      type: String,
      default: 'opened'
    }
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  },
  methods: {
    goTo (route) {
      if (this.$route.name === route) {
        return
      }
      this.$router.push({ name: route })
    }
  }
}
</script>

<style lang="less">
  @import 'bookings.less';
  .tab-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    margin-left: -15px;
    margin-right: -15px;
    &::-webkit-scrollbar {
      display: none;
    }
    > .container {
      min-width: 340px;
    }
  }
</style>
