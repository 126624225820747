<template>
  <Bookings
    :displayedCard="visibleItemMenu"
  >
    <b-card
      align="left"
      class="booking-opened-card side-card positions"
      header-tag="header"
      no-body
    >
      <b-card-header>
        <b-container>
          <b-row v-if="broadcasted">
            <b-col class="header-info">
              <h4>{{ position.title }}</h4>
              <div class="position-side">
                <div v-if="position.getOpened" class="opened-position pink text-nowrap">
                  <span class="text-position d-none d-sm-inline-block">posición abierta</span>
                  <img
                    alt="Posición abierta"
                    class="pink"
                    :src="require('@/assets/icons/booking-opened-pink.svg')"
                  >
                </div>
                <div v-if="!position.getOpened" class="closed-position text-nowrap">
                  <span class="text-position d-none d-sm-inline-block">posición cerrada</span>
                  <img
                    alt="Posición cerrada"
                    :src="require('@/assets/icons/booking-closed.svg')"
                  >
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex flex-column line-space position-info" v-if="positionLoaded">
                <span class="text">
                  Fecha:
                  <span class="pink font-weight-bold">{{ positionDate }}</span>
                </span>
                <span class="text">
                  Hora:
                  <span class="pink font-weight-bold">{{ positionTime }}</span>
                </span>
                <span class="text">
                  Roles:
                  <span class="pink font-weight-bold" v-if="roles">{{ roles }}</span>
                  <span class="pink font-weight-bold" v-else>No requerido</span>
                </span>

                <span class="text">
                  Habilidades:
                  <span class="pink font-weight-bold" v-if="skills">{{ skills }}</span>
                  <span class="pink font-weight-bold" v-else>No requeridas</span>
                </span>

                <span class="text">
                  Licencias:
                  <span class="pink font-weight-bold" v-if="licenses">{{ licenses }}</span>
                  <span class="pink font-weight-bold" v-else>No requeridas</span>
                </span>

                <span class="text">
                  Precio:
                  <span class="pink font-weight-bold" v-if="position">{{ position.pricePerDay }}</span>
                </span>

                <span class="text">
                  Anotaciones:
                  <span class="pink font-weight-bold">{{ position.annotation }}</span>
                </span>
              </div>
              <div v-else>
                <span>Cargando...</span>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-card-header>
    </b-card>
    <b-card
      align="left"
      class="booking-opened-card side-card positions"
      v-if="booking && !position.getOpened && contactedTechnicians"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h4>Técnico seleccionado</h4>
          </b-col>
        </b-row>
      </template>
      <b-card-body>
        <b-container>
          <b-row
            v-for="contactedTechnician in selectedTechnicians"
            :key="contactedTechnician.id"
          >
            <b-col v-if="contactedTechnician.businessAccepted">
              <BookingPositionTechnician
                :bookingId="booking.id"
                :contactedTechnician="contactedTechnician"
                :position="position"
                :showButtons="position.getOpened"
                :showCancelPosition="!position.getOpened"
                @updateData="loadData"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
      <b-card-body v-if="!position.getOpened && contactedTechnicians == null">
        <b-container>
          <b-row>
            <b-col>
              Cargando...
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
    <b-card
      align="left"
      class="booking-opened-card side-card positions contacted-technicians"
      v-if="booking && listTechniciansRequests.length > 0"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h4>Solicitudes</h4>
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="contactedTechnicians" class="list-position-technician">
        <b-container v-if="listTechniciansRequests.length > 0">
          <b-row
            v-for="contactedTechnician in listTechniciansRequests"
            :key="contactedTechnician.id"
          >
            <b-col v-if="!contactedTechnician.businessAccepted">
              <BookingPositionTechnician
                :bookingId="booking.id"
                :contactedTechnician="contactedTechnician"
                :position="position"
                :showButtons="position.getOpened"
                @updateData="loadData"
              />
              <hr class="divider" />
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else>
          <b-row>
            <b-col>
              <div>
                <strong>¡Vaya!</strong>
              </div>
              Parece que no tienes ninguna solicitud
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
      <b-card-body v-if="contactedTechnicians == null">
        <b-container>
          <b-row>
            <b-col>
              Cargando...
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
  </Bookings>
</template>

<script>
import Bookings from '@/views/Bookings.vue'
import BookingPositionTechnician from '@/components/BookingPosition/BookingPositionTechnician.vue'

export default {
  name: 'Booking',
  data: function () {
    return {
      position: null,
      booking: null,
      contactedTechnicians: null
    }
  },
  components: {
    Bookings,
    BookingPositionTechnician
  },
  created: async function () {
    await this.loadData()
  },
  computed: {
    visibleItemMenu () {
      if (this.booking) {
        if (!this.booking.getOpened) {
          return 'closed'
        }
        return 'opened'
      }
      return ''
    },
    positionLoaded () {
      return this.position !== null
    },
    broadcasted () {
      if (!this.booking) return
      return this.booking.broadcasted
    },
    roles () {
      if (!this.position) return
      return this.joinArrayNames(this.position.getRoles)
    },
    skills () {
      if (!this.position) return
      return this.joinArrayNames(this.position.getSkills)
    },
    licenses () {
      if (!this.position) return
      return this.joinArrayNames(this.position.getDrivingLicenses)
    },
    listTechniciansRequests () {
      if (this.contactedTechnicians === null) return []
      return this.contactedTechnicians.filter(contactedTechnician => {
        return !contactedTechnician.businessAccepted
      })
    },
    selectedTechnicians () {
      if (this.contactedTechnicians === null) return []
      return this.contactedTechnicians.filter(contactedTechnician => {
        return contactedTechnician.businessAccepted
      })
    },
    positionDate () {
      return this.position.getEventDate
    },
    positionTime () {
      return this.position.getOnlyTime
    }
  },
  methods: {
    async loadData () {
      this.booking = await this.getBooking()
      this.position = this.getPosition(this.booking.positions)
      this.contactedTechnicians = await this.getContactedTechnicians()
      this.$forceUpdate()
    },
    async getBooking () {
      return await this.$store.dispatch('getBookingById', this.$route.params.id)
    },
    getPosition (positions) {
      return positions.find(element => {
        return element.id === this.$route.params.positionId
      })
    },
    async getContactedTechnicians () {
      const data = {
        bookingId: this.$route.params.id,
        bookingPositionId: this.$route.params.positionId
      }
      return await this.$store.dispatch('contactedTechnicianFromBusiness', data)
    },
    joinArrayNames (array) {
      return array.map(item => { return item.name }).join(', ')
    }
  }
}
</script>

<style lang="less" scoped>
@import 'viewBooking.less';

  .header-info {
    margin: 1.25rem 0;
    display: flex;
    justify-content: space-between;
  }

  .line-space {
    line-height: 24px;
  }

  .text-position {
    margin-right: 0.5rem;
    background-color: transparent;
  }

  .position-info {
    padding-bottom: 50px;
  }

  .contacted-technicians {
    .card-header {
      h4 {
        margin: 0;
        padding: 0;
        font-size: 1.25rem;
      }
    }
  }

  .card-body {
    background-color: @color_dark_blue;
  }

  .divider {
    border-top: 1px solid @color_dark_purple;
    border-bottom-width: 0;
    margin: 1rem -32px 1rem -40px;
  }

  .list-position-technician {
    padding-right: 1rem !important;
  }
</style>
